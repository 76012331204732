/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider
    .state('feedback', {
      abstract: true,
      parent: 'root',
      url: '/feedback',
      controller: 'acpFeedbackSectionCtrl',
      templateUrl: 'apps/feedback/templates/acp-feedback.ng.html',
      redirectTo: 'feedback.main',
      data: {
        permissions: {
          only: 'hasFeedback',
          redirectTo: {
            hasFeedback: 'dashboard'
          }
        }
      },
      resolve: {
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.apps.feedback" */ 'apps/feedback').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('feedback.main', {
      url: '',
      templateUrl: 'apps/feedback/templates/acp-feedback-main.ng.html',
      data: {
        permissions: {
          only: 'isOac4FeedbackEnabled',
          redirectTo: {
            isOac4FeedbackEnabled: 'dashboard'
          }
        }
      }
    })
    .state('feedback.confirmation', {
      url: '/confirmation',
      templateUrl: 'apps/feedback/templates/acp-feedback-confirmation.ng.html'
    });
}

export default states;
